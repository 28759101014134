import React from "react";
import { Link } from "gatsby";
import LRT from "../../assets/svg/logo-lrt.svg";
import EU from "../../assets//svg/logo-europos-komisija.svg";
import PREZ from "../../assets/svg/logo-prezidentas.svg";
import MINIS from "../../assets/svg/logo-ministerija.svg";
import SMSM from "../../assets/svg/logo-smsm.svg";
import EUD from "../../assets/svg/logo-europe-direct.svg";
import JEK from "../../assets/svg/logo-JEK.svg";
import "./Footer.scss";

function Footer() {
  return (
    <div className="footer" id="CTA">
      <div className="topSpon">
        <div className="orgSpon">
          <p>Projektą organizuoja:</p>
          <a href = "https://lithuania.representation.ec.europa.eu/index_lt"target="_blank" rel="noreferrer">
            <img src={EU} alt="Europos sąjungos komisijos logotipas"/>
          </a>
          <a href = "https://www.lrt.lt/" target="_blank" rel="noreferrer">
            <img src={LRT} alt="LRT logotipas" id="lrtLogo"/>
          </a>
        </div>
        <div className="globSpon">
          <p>Projektą globoja:</p>
          <a href = "https://lrp.lt/lt" target="_blank" rel="noreferrer">
            <img src={PREZ} alt="Prezidento logotipas" id="prezLogo"/>
          </a>
        </div>
      </div>
      <div className="draugSpon"> 
        <p>Projekto draugai:</p>
        <div className="draugLogo">
          <a href = "https://www.urm.lt/"target="_blank" rel="noreferrer">
            <img src={MINIS} alt="Lietuvos respublikos užsienio reikalų ministerijos logotipas"/>
          </a>
          <a href = "https://smsm.lrv.lt/lt/"target="_blank" rel="noreferrer">
            <img src={SMSM} alt="Švietimo ministerijos logotipas"/>
          </a>
          <a href = "https://eudirect.lt/"target="_blank" rel="noreferrer">
            <img src={EUD} alt="Europe Direct logotipas"/>
          </a>
          <a href = "https://www.facebook.com/JaunimoEuroposkomanda"target="_blank" rel="noreferrer">
            <img src={JEK} alt="JEK logotipas"/>
          </a>
        </div>
      </div>
      <hr />
      <div className="botLinks">
        <Link to="/privatumas" id="privatBot">
          <p id="CTA-Meniu">PRIVATUMO NUOSTATOS</p>
        </Link>
        <p id="CTA-Meniu">
          Susisiekite: <a href="mailto:europosegzaminas@lrt.lt">europosegzaminas@lrt.lt</a>
        </p>
      </div>
    </div>
  );
}

export default Footer;
