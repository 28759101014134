import React from "react"
import { Link } from "gatsby"
import cx from "classnames"
import Arrow from "../../assets/images/Arrow 2.png";
import "./Buttons.scss"

const RaisedButton = ({ children, to, href, variant, ...rest }) => {
  const Element = href ? `a` : to ? Link : `button`

  const props = {}

  if (to) {
    props.to = to
  }
  if (href) {
    props.href = href
  }

  return (
    <Element
      className={cx("raised-button", {
        [`variant-${variant}`]: variant,
      })}
      {...rest}
      {...props}
    >
      {children}
    </Element>
  )
}

const GhostButton = ({ children, to, href, variant, ...rest }) => {
  const Element = href ? `a` : to ? Link : `button`

  const props = {}

  if (to) {
    props.to = to
  }
  if (href) {
    props.href = href
  }

  return (
    <Element
      className={cx("ghost-button", {
        [`variant-${variant}`]: variant,
      })}
      {...rest}
      {...props}
    >
      {children}
    </Element>
  )
}

const ArrowAtgal = ({ children, to, href, variant, ...rest }) => {
  const Element = href ? `a` : to ? Link : `button`

  const props = {}

  if (to) {
    props.to = to
  }
  if (href) {
    props.href = href
  }

  return (
    <Element
      className={cx("arrow-atgal", {
        [`variant-${variant}`]: variant,
      })}
      {...rest}
      {...props}
    >
      <img src={Arrow} alt="Rodyklė rodanti į kairę pusę"/>
      {children}
    </Element>
  )
}

const ArrowToliau = ({ children, to, href, variant, ...rest }) => {
  const Element = href ? `a` : to ? Link : `button`

  const props = {}

  if (to) {
    props.to = to
  }
  if (href) {
    props.href = href
  }

  return (
    <Element
      className={cx("arrow-toliau", {
        [`variant-${variant}`]: variant,
      })}
      {...rest}
      {...props}
    >
      {children}
      <img src={Arrow} alt="Rodyklė rodanti į dešinę pusę"/>
    </Element>
  )
}

export { RaisedButton, ArrowToliau, GhostButton, ArrowAtgal }
