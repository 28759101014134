import React from "react"
import { Helmet } from "react-helmet"
import Seo from "../Seo"
import Footer from "../Footer"
import Header from "../Header";
import "../../assets/sass/main.scss"
import "./Layout.scss"

export default ({ compact, children }) => {
  return (
    <>
      <Seo />
      <Helmet>

        <script async="" src="https://www.googletagmanager.com/gtm.js?id=GTM-NNQ95HK"></script>

        <script>
          {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','GTM-NNQ95HK');`}
        </script>



        <script>
          {`<!--//--><![CDATA[//><!--
          var pp_gemius_identifier = 'p81F.PctfbCWHlNgXPBAJuV17Hlpa0P3.YJgAJxknZL.M7';
          // lines below shouldn't be edited
          function gemius_pending(i) { window[i] = window[i] || function() {var x = window[i+'_pdata'] = window[i+'_pdata'] || []; x[x.length]=arguments;};};
          gemius_pending('gemius_hit'); gemius_pending('gemius_event'); gemius_pending('pp_gemius_hit'); gemius_pending('pp_gemius_event');
          (function(d,t) {try {var gt=d.createElement(t),s=d.getElementsByTagName(t)[0],l='http'+((location.protocol=='https:')?'s':''); gt.setAttribute('async','async');
              gt.setAttribute('defer','defer'); gt.src=l+'://galt.hit.gemius.pl/xgemius.js'; s.parentNode.insertBefore(gt,s);} catch (e) {}})(document,'script');
          //--><!]]>`}
        </script>
      </Helmet>
      <div className="layout">
        <Header />
        <div>{children}</div>
        <Footer />
      </div>
    </>
  )
}
